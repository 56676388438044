body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Mobile Styles */
@media only screen and (max-width: 400px) {
  body {}

  td.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
    white-space: nowrap;

  }

  td.listing-list-cell:last-child {
    padding-right: 4px;
  }

  th.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
  }

  th.listing-list-cell:last-child {
    padding-right: 4px;
  }

  .hmdUserList {
    height: 175px;
    overflow-y: scroll;
  }

  .button-operations {

    -webkit-align-items: flex-start;

            align-items: flex-start;

  }

  .button-operations button {
    float: left;
    padding: 0px;
    width: 40%;
    font-size: 0.5em;
  }

  .button-operations label {
    float: left;
    padding: 0px;
    width: 40%;
    font-size: 0.5em;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .shooting-planner {}


}

/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {
  body {}

  .hmdUserList {
    height: 200px;
    overflow-y: scroll;
  }



}

/* Desktop Styles */
@media only screen and (min-width: 961px) {
  body {}

  .hmdUserList {
    height: 200px;
    overflow-y: scroll;
  }

  p div .mobile-select {
    /* display: none; */

  }

  div .mobile-select {
    /* display: none; */

  }

  #mobil-unassign {
    /* display: none; */
  }

  .mobile-select {
    /* display: none; */
  }

  .unass {
    display: none;

  }

  td.listing-list-cell {
    padding-right: 10px;
    padding: 4px 4px 4px 4px;
    white-space: nowrap;

  }
}

.no-bullet {

  list-style-type: none;
}


.greyGrad {
  background: linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 37%, rgba(250, 250, 250, 1) 95%, rgba(250, 250, 250, 1) 100%);
  /*linear-gradient(to top, #edf4ff, #eef1fa, #eeeff5, #ededf0, #ebebeb);*/
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(246, 246, 246, 1) 37%, rgba(255, 255, 255, 1) 95%, rgba(209, 209, 209, 1) 100%);
}

a {
  text-decoration: none;
  color: white;
}

a.rbc-show-more {
  color: black !important
}

.App {
  text-align: center;
  
}
.xmas{
  background-color: #0F8A5F;
 
}

.xmas .menu-div {
  color:white;
  background-color: #34A65F;
}

.xmas .menu-div span{
  color:white;
}
 
.xmas main div {
 
  
 /*  background-color: white; */
}  

.xmas header{
   background-color: #CC231E;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
