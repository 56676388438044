a {
  text-decoration: none;
  color: white;
}

a.rbc-show-more {
  color: black !important
}

.App {
  text-align: center;
  
}
.xmas{
  background-color: #0F8A5F;
 
}

.xmas .menu-div {
  color:white;
  background-color: #34A65F;
}

.xmas .menu-div span{
  color:white;
}
 
.xmas main div {
 
  
 /*  background-color: white; */
}  

.xmas header{
   background-color: #CC231E;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}